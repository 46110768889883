
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Icon from "@/components/reusable/Icon.vue";
import { EventBus } from "@/events/index";
import { DraggableEvent } from "@/models/utility";
import { makeSentenceCase } from "@/utility/helpers";

const sortValueOptions = [
  "display_name",
  "title",
  "item_number",
  "manufacturer",
  "series",
  "model",
  "file_type",
  "size_h",
  "last_modified",
  "filename"
] as const;

@Component({
  components: {
    draggable,
    Icon
  }
})
/**
 * Base class for all table components
 */
export default class Table extends Vue {
  @Prop() data!: any[];
  @Prop() disableDrag!: boolean;
  @Prop({ default: true }) isSubCat!: boolean;
  protected tableData: any[] = [];
  protected sortFields = [
    "display_name",
    "title",
    "item_number",
    "manufacturer",
    "series",
    "model",
    "file_type",
    "size_h",
    "last_modified",
    "filename"
  ];
  protected drag = false;
  protected selected: number[] = [];
  protected moveCursor = false;
  protected showHidden = false;
  protected showEmpty = false;
  protected makeSentenceCase = makeSentenceCase;
  protected showActive = true;
  protected sort: {
    value: typeof sortValueOptions[number];
    order: "asc" | "desc";
  } = { value: "display_name", order: "asc" };
  created() {
    this.tableData = this.data;
  }

  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.hidden && this.$refs.inputShowHidden) {
        (this.$refs.inputShowHidden as HTMLInputElement).checked = true;
        this.showHidden = true;
      }
      if (this.$route.query.empty && this.$refs.inputShowEmpty) {
        (this.$refs.inputShowEmpty as HTMLInputElement).checked = true;
        this.showEmpty = true;
      }
    });
  }

  /**
   * Keeping all data synced.
   * this.data is the data returned from the API
   */
  @Watch("data", { deep: true })
  onDragAction(): void {
    this.tableData = this.data;
  }

  /**
   * Setting sort properties on user selection, and toggling column arrows based on asc/desc
   */
  public selectSort(sortByValue: typeof sortValueOptions[number]): void {
    if (this.sort.value !== sortByValue) {
      this.sort.value = sortByValue;
      this.sort.order = "asc";
      (this.$refs[sortByValue] as HTMLSpanElement).innerHTML =
        '<span class="sort sort-up"></span>';
      this.sortFields.forEach(field => {
        if (this.$refs[field]) {
          (this.$refs[field] as HTMLSpanElement).innerHTML =
            '<span class="sort sort-up"></span>';
        }
      });
    } else {
      if (this.sort.order === "desc") {
        this.sort.order = "asc";
        (this.$refs[sortByValue] as HTMLSpanElement).innerHTML =
          '<span class="sort sort-up"></span>';
      } else {
        this.sort.order = "desc";
        (this.$refs[sortByValue] as HTMLSpanElement).innerHTML =
          '<span class="sort sort-down"></span>';
      }
    }
  }

  get filteredData(): any[] {
    const filtered = this.tableData;
    if (this.sort.value === "filename") {
      if (this.sort.order === "asc") {
        //@ts-ignore
        filtered.sort((first, second) => {
          if (
            (first["display_name"].toLowerCase()
              ? first["display_name"].toLowerCase()
              : first["filename"].toLowerCase()) <
            (second["display_name"].toLowerCase()
              ? second["display_name"].toLowerCase()
              : second["filename"].toLowerCase())
          )
            return -1;
          if (
            (first["display_name"].toLowerCase()
              ? first["display_name"].toLowerCase()
              : first["filename"].toLowerCase()) >
            (second["display_name"].toLowerCase()
              ? second["display_name"].toLowerCase()
              : second["filename"].toLowerCase())
          )
            return 1;
        });
      }
      if (this.sort.order === "desc") {
        //@ts-ignore
        filtered.sort((first, second) => {
          if (
            (first["display_name"].toLowerCase()
              ? first["display_name"].toLowerCase()
              : first["filename"].toLowerCase()) <
            (second["display_name"].toLowerCase()
              ? second["display_name"].toLowerCase()
              : second["filename"].toLowerCase())
          )
            return 1;
          if (
            first["display_name"].toLowerCase()
              ? first["display_name"].toLowerCase()
              : first["filename"].toLowerCase() >
                second["display_name"].toLowerCase()
              ? second["display_name"].toLowerCase()
              : second["filename"].toLowerCase()
          )
            return -1;
        });
      }
    } else if (this.sort.value === "manufacturer") {
      // need different logic since mfr is an object, not a string
      if (this.sort.order === "asc") {
        //@ts-ignore
        filtered.sort((first, second) => {
          if (
            first.manufacturer["display_name"].toLowerCase() <
            second.manufacturer["display_name"].toLowerCase()
          )
            return -1;
          if (
            first.manufacturer["display_name"].toLowerCase() >
            second.manufacturer["display_name"].toLowerCase()
          )
            return 1;
        });
      }
      if (this.sort.order === "desc") {
        //@ts-ignore
        filtered.sort((first, second) => {
          if (
            first.manufacturer["display_name"].toLowerCase() <
            second.manufacturer["display_name"].toLowerCase()
          )
            return 1;
          if (
            first.manufacturer["display_name"].toLowerCase() >
            second.manufacturer["display_name"].toLowerCase()
          )
            return -1;
        });
      }
    } else {
      if (this.sort.order === "asc") {
        //@ts-ignore
        filtered.sort((first, second) => {
          if (
            first[this.sort.value].toLowerCase() <
            second[this.sort.value].toLowerCase()
          )
            return -1;
          if (
            first[this.sort.value].toLowerCase() >
            second[this.sort.value].toLowerCase()
          )
            return 1;
        });
      }
      if (this.sort.order === "desc") {
        //@ts-ignore
        filtered.sort((first, second) => {
          if (
            first[this.sort.value].toLowerCase() <
            second[this.sort.value].toLowerCase()
          )
            return 1;
          if (
            first[this.sort.value].toLowerCase() >
            second[this.sort.value].toLowerCase()
          )
            return -1;
        });
      }
    }
    return filtered;
  }

  public moveSelectedRows(): void {
    const moveInfo: any[] = [];
    this.selected.forEach((id, index) => {
      this.tableData.forEach(row => {
        if (row.id === id) {
          moveInfo.push(row);
          if (index === this.selected.length - 1) {
            this.$emit("move", moveInfo);
          }
        }
      });
    });
  }
  public dragEnd($event: DraggableEvent): void {
    this.drag = false;
    if (!this.disableDrag) {
      if ($event.oldIndex !== $event.newIndex) {
        this.$emit(
          "reorder",
          $event.item.id,
          $event.oldIndex + 1,
          $event.newIndex + 1
        );
      }
    }
  }

  /**
   * Method that checks/unchecks all checkboxes if master checkbox $ref.inputAll is changed
   * All inputs are assigned same ref so $ref.inputRow is an array
   * Iterate over array to check boxes on/off
   */
  public checkAllBoxesRows() {
    if ((this.$refs.inputAll as HTMLInputElement).checked) {
      (this.$refs.inputRow as HTMLInputElement[]).forEach(input => {
        if (!input.checked) {
          input.checked = true;
          this.selected.push(parseInt(input.value, 10));
        }
      });
    } else {
      this.resetAllCheckboxRowData();
    }
  }
  /**
   * Clears all ids from this.selected
   */
  public resetAllCheckboxRowData(): void {
    if (this.selected.length > 0) {
      (this.$refs.inputRow as HTMLInputElement[]).forEach(input => {
        input.checked = false;
      });
    }
    (this.$refs.inputAll as HTMLInputElement).checked = false;
    this.selected = [];
  }

  // public showAllRows(): void {
  //   this.resetAllCheckboxRowData();
  //   if ((this.$refs.inputShowAll as HTMLInputElement).checked) {
  //     this.showActive = false;
  //   } else {
  //     this.showActive = true;
  //   }
  // }

  public showHiddenRows(): void {
    this.resetAllCheckboxRowData();
    if ((this.$refs.inputShowHidden as HTMLInputElement).checked) {
      this.showHidden = true;
    } else {
      this.showHidden = false;
    }
    this.getCheckboxInfo();
  }

  public showEmptyRows(): void {
    this.resetAllCheckboxRowData();
    if ((this.$refs.inputShowEmpty as HTMLInputElement).checked) {
      this.showEmpty = true;
    } else {
      this.showEmpty = false;
    }
    this.getCheckboxInfo();
  }

  protected getCheckboxInfo(): void {
    const query: any = {};
    if (this.showEmpty) {
      query.empty = "true";
    }
    if (this.showHidden) {
      query.hidden = "true";
    }
    if (this.$route.query.q) {
      query.q = this.$route.query.q;
    }
    this.$router.push({ query: { ...query } });
  }

  public showSelectedRows(): void {
    this.tableData.forEach(row => {
      this.selected.forEach(id => {
        if (row.id === id) {
          row.is_hidden = false;
        }
      });
    });
    this.resetAllCheckboxRowData();
  }

  public deleteSelectedRows(): void {
    const deleteInfo: any[] = [];
    this.selected.forEach((id, index) => {
      this.tableData.forEach(row => {
        if (row.id === id) {
          deleteInfo.push(row);
          if (index === this.selected.length - 1) {
            EventBus.$emit("deleteRow", deleteInfo);
          }
        }
      });
    });
  }

  public goToEditRow(id: number): void {
    this.$router.push(this.$route.path + "/edit/" + id);
  }
}
