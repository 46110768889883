



































































































































































































































import { Component, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import CategoryMenu from "@/components/category/CategoryMenu.vue";
import Icon from "@/components/reusable/Icon.vue";
import Table from "@/components/reusable/table/Table.vue";


@Component({
  components: {
    draggable,
    CategoryMenu,
    Icon
  }
})
export default class CategoryTable extends Table {

  @Watch("tableData", { deep: true })
  onDataChange(): void {
    this.resetAllCheckboxRowData();
  }

  public async visibilityRequest(id: number, hide: boolean): Promise<void> {
    this.$emit("editVisibility", [id], hide);
  }

  public visibilityRequestMultiple(hide: boolean): void {
    this.$emit("editVisibility", this.selected, hide);
    this.resetAllCheckboxRowData();
  }

    public showHiddenRowsSubCat(): void {
    this.resetAllCheckboxRowData();
    if ((this.$refs.inputShowHidden as HTMLInputElement).checked) {
      this.showHidden = true;
    } else {
      this.showHidden = false;
    }
    this.$emit('filterSubCategories', 'hide', this.showHidden);
  }

  public showEmptyRowsSubCat(): void {
    this.resetAllCheckboxRowData();
    if ((this.$refs.inputShowEmpty as HTMLInputElement).checked) {
      this.showEmpty = true;
    } else {
      this.showEmpty = false;
    }
    this.$emit('filterSubCategories', 'empty', this.showEmpty);
  }

}
